import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Loader from './components/Loader'
import './App.css'
import { PUBLIC } from './routeConstants'

const publicRoutes = [
  {
    path: PUBLIC.HOME,
    exact: true,
    component: lazy(() => import('./pages/Home')),
  },
  {
    path: PUBLIC.ABOUT,
    exact: true,
    component: lazy(() => import('./pages/About')),
  },
  {
    path: PUBLIC.SERVICES,
    exact: true,
    component: lazy(() => import('./pages/Services')),
  },
]

const App = () => (
  <Router>
    <Switch>
      <Suspense fallback={<Loader />}>
        {publicRoutes.map((route, index) => (
          <Route key={index} path={route.path} exact={route.exact}>
            <route.component />
          </Route>
        ))}
      </Suspense>
    </Switch>
  </Router>
)

export default App
